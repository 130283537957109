import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/Pay.css';
import CartDescription from '../Shared/cart-description/CartDescription';
import PaymentForm from './components/payment-form/PaymentForm';
import PickupDetails from './components/pickup-details/PickupDetails';
import { Link } from 'react-router-dom';
import TipsPicker from './components/tips-picker/TipsPicker';
import ReactGA from "react-ga4";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'
import withNavigation from '../withNavigation/withNavigation';

class Pay extends React.Component {

    state = {
        cart: { lineItems: null },
        tip: 0,
        showClosedMessage: false
    };

    componentDidMount() {
        document.title = `Pay | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        
        if (currentLocation && orderId) {
            this.props.setLocation(currentLocation);
            this.getCart(orderId);
            
            // Check store hours once locations are available
            if (this.props.locations?.length) {
                this.validateStoreHours(currentLocation);
            }
        } else {
            window.location.href = "/";
        }
    }

    componentDidUpdate(prevProps) {
        // Check store hours when locations become available
        if (!prevProps.locations?.length && this.props.locations?.length) {
            const currentLocation = localStorage.getItem('locationId');
            this.validateStoreHours(currentLocation);
        }
    }

    validateStoreHours = (locationId) => {
        if (!this.isStoreOpen(locationId)) {
            // delete orderId
            localStorage.removeItem('orderId');
            this.setState({ showClosedMessage: true });
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        }
    }

    isStoreOpen = (locationId) => {
        const location = this.props.locations?.find(loc => loc.id === locationId);
        if (!location?.business_hours?.periods) return false;

        const now = new Date();
        // now.setHours(20, 0, 1); // Setting to 8:00:01 PM
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const currentDay = daysOfWeek[now.getDay()];
        
        const todayHours = location.business_hours.periods.find(
            period => period.day_of_week === currentDay
        );
        
        if (!todayHours) return false;

        const currentTime = now.toLocaleTimeString('en-US', { 
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        return currentTime >= todayHours.start_local_time && 
               currentTime <= todayHours.end_local_time;
    }

    render() {
        const { catalog, location } = this.props;
        const { cart, showClosedMessage } = this.state;

        if (showClosedMessage) {
            return (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center" 
                     style={{ backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1050 }}>
                    <div className="bg-white p-4 rounded-3 text-center shadow-lg">
                        <h4 className="poppins text-color6 mb-3">Store Closed</h4>
                        <p className="mb-3">Sorry, this location is currently closed.</p>
                        <p className="mb-0"><small>Redirecting to home page   ...</small></p>
                    </div>
                </div>
            );
        }

        if (!catalog || !catalog.length || !location || !cart.lineItems) {
            return <LoadingScreen imageSource={loadingImage} />;
        }

        return (
            <div className='pay-container'>
                {/* Corner Logo - Moved outside of header */}
                <div className='corner-logo d-none d-md-block'>
                    <Link to="/">
                        <img src={data.corner_logo} alt="Corner Logo" />
                    </Link>
                </div>

                {/* Header Section */}
                <div className='pay-header'>
                    <div className="back-to-home">
                        <Link 
                            to="/" 
                            className='back-link'
                            onClick={this.clearLocalStorage}
                        >
                            <img src={data.Back} alt='Back to home' />
                            <span>Start new order</span>
                        </Link>
                    </div>
                </div>

                {/* Main Content */}
                <div className="pay-content">
                    <div className="container">
                        <div className="row g-4">
                            {/* Payment Section */}
                            <div className="col-12 col-lg-7 order-2 order-lg-1">
                                <div className="payment-section">
                                    {/* Tips Section */}
                                    <div className="tips-section mb-4">
                                        <TipsPicker 
                                            cart={cart} 
                                            submitTip={this.submitTip} 
                                        />
                                    </div>

                                    <div className="payment-form-section">
                                        <PaymentForm 
                                            cards={this.props?.cards}
                                            cart={cart}
                                            locations={this.props?.locations}
                                            location={location}
                                            addPickupDetails={this.addPickupDetails}
                                            tip={this.state.tip}
                                            attributes={this.props.attributes}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Cart Section */}
                            <div className="col-12 col-lg-5 order-1 order-lg-2">
                                <div className="cart-section">
                                    <h3 className='section-title'>Order Summary</h3>
                                    <div className="cart-container">
                                        <CartDescription 
                                            edit={false}
                                            updateItem={this.updateItem}
                                            items={cart}
                                            setQuantity={this.setQuantity}
                                            catalog={catalog}
                                            tip={this.state.tip}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addPickupDetails = (state) => {
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        const body = {
            locationId: currentLocation,
            orderId: orderId,
            pickupName: state?.pickup_details?.name,
            pickupEmail: state?.pickup_details?.email,
            pickupNumber: state?.pickup_details?.phone
        }
        axios.patch(process.env.REACT_APP_API_URL + 'orders/update', body).then(res => {
            this.setState({ cart: res?.data?.order });
        });
    }

    getCart(orderId) {
        axios.get(process.env.REACT_APP_API_URL + 'orders/?orderId=' + orderId).then(res => {
            if (!res?.data?.lineItems || res?.data?.lineItems.length === 0) {
                this.props.navigate("/menu");
            }
            this.setState({ cart: res?.data })
        });

    }

    pickupOrPayment() {
        if (this.state?.cart?.fulfillments)
            return (
                <div>
                    <h3 className='poppins text-color6'>Contact</h3>
                    <PaymentForm cart={this.state?.cart} location={this.props?.location} locations={this.props?.locations} addPickupDetails={this.addPickupDetails} tip={this.state.tip} attributes={this.props.attributes} />
                </div>
            );
        else
            return (
                <div>
                    <h3 className='poppins text-color6'>Contact</h3>
                    <PickupDetails addPickupDetails={this.addPickupDetails} />
                </div>
            );
    }

    setQuantity = (uid, quantity) => {
        this.state?.cart?.lineItems?.forEach(item => {
            if (item.uid === uid) {
                item.quantity = quantity;
                if (quantity !== 0)
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.id,
                        "locationId": this.state?.cart?.locationId,
                        "lineItems": this.state?.cart?.lineItems,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    })
                        .then(res => {
                            localStorage.setItem('orderId', res?.data?.order?.id);
                            this.setState({ cart: res?.data?.order });
                        });
                else {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.id,
                        "deleteItem": [uid],
                        "locationId": this.state?.cart?.locationId,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    })
                        .then(res => {
                            localStorage.setItem('orderId', res?.data?.order?.id);
                            this.setState({ cart: res?.data?.order });
                        });
                }
            }
        });
    }

    updateItem = (product_to_update) => {
        let cart = this.state?.cart;
        const updatedLineItems = cart?.lineItems?.map((item) => {
            if (item.uid === product_to_update?.uid) {
                return {
                    ...item,
                    modifiers: product_to_update.modifiers,
                    quantity: product_to_update.quantity,
                    catalogObjectId: product_to_update.variation,
                };
            }
            return item;
        });

        const updatedLineItemsWithDeletedFields = updatedLineItems.reduce((acc, item) => {
            return {
                ...acc,
                [item.uid]: {
                    ...item,
                    grossSalesMoney: undefined,
                    totalTaxMoney: undefined,
                    totalMoney: undefined,
                    totalDiscountMoney: undefined,
                    appliedTaxes: undefined
                }
            }
        }, {});

        axios.patch(
            process.env.REACT_APP_API_URL + 'orders/update', {
            "orderId": cart?.id,
            "locationId": this.props.location,
            "deleteItem": product_to_update.modifiers_to_delete,
            "calculationPhase": "TOTAL_PHASE",
            "state": "OPEN"
        })
            .then(res =>
                axios.patch(
                    process.env.REACT_APP_API_URL + 'orders/update', {
                    "orderId": cart?.id,
                    "locationId": this.props.location,
                    "lineItems": updatedLineItemsWithDeletedFields,
                    "calculationPhase": "TOTAL_PHASE",
                    "state": "OPEN"
                })
                    .then(res => this.setState({ cart: res?.data.order }))
            );
    }

    submitTip = (tip) => {
        this.setState({ tip });
    }

    clearLocalStorage = () => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key !== 'jwt' && key !== 'squareId' && key !== 'locationId') {
                localStorage.removeItem(key);
            }
        }
    };

}

export default withNavigation(Pay);
