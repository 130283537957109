import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
const APP_VERSION = "1.2"; // Change this version whenever you want to invalidate old localStorage data

const storedVersion = localStorage.getItem('appVersion');
if (!storedVersion) {
  // Set the version for the first time
  localStorage.setItem('appVersion', APP_VERSION);
} else if (storedVersion !== APP_VERSION) {
  // Clear the old localStorage data
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key !== 'jwt' && key !== 'squareId') {
      localStorage.removeItem(key);
    }
  }
  // Update the localStorage version
  localStorage.setItem('appVersion', APP_VERSION);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
